<template>
  <div class="set-date-time-dialog" v-show="showDateTimeError">
    <div class="set-date-time-backdrop" @click="showDateTimeError = false" />
    <q-card class="set-date-time">
      <q-card-section class="q-px-lg q-pt-lg">
        <q-btn
          flat
          round
          dense
          color="dark"
          icon="close"
          class="absolute-top-right q-mt-sm q-mr-sm"
          @click="showDateTimeError = false"
        />
        <SvgIcon size="64px" icon="datetime" />
      </q-card-section>

      <q-card-section class="q-px-lg q-pb-lg q-pt-none">
        <div class="text-title">
          Your clock is {{ isBehindError ? 'behind' : 'ahead' }}
        </div>
        <div class="text-subtext">
          <q-no-ssr>
            A private connection to {{ hostName }} can't be established because
            your device's date and time ({{
              new Date() | formatDate('dddd, MMMM DD, YYYY at HH:mm:ss A')
            }}) are incorrect.
            <br />
            Please adjust the date & time settings on your device and then
            proceed to sign in.
          </q-no-ssr>

          <!-- It appears that the date & time settings on your device are
          mismatched. Please adjust the date & time settings on your device and
          then proceed to sign in. -->
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SetDateTime',
  computed: {
    hostName() {
      if (typeof window !== 'undefined') return window.location.hostname
      return 'app'
    },
    ...mapGetters('common', ['commonStateByKey']),
    isBehindError() {
      return this.commonStateByKey('showDateTimeBehindError') || false
    },
    isAheadError() {
      return this.commonStateByKey('showDateTimeAheadError') || false
    },
    showDateTimeError: {
      get() {
        return this.isBehindError || this.isAheadError
      },
      set(val) {
        this.$store.commit('common/UPDATE_STATE', {
          key: this.isBehindError
            ? 'showDateTimeBehindError'
            : 'showDateTimeAheadError',
          val,
        })
      },
    },
  },
}
</script>

<style lang="scss">
.set-date-time-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  .set-date-time-backdrop {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
}
.set-date-time {
  margin: 0 auto;
  width: 420px;
  max-width: 80vw;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 12px;
  border-radius: 12px;
  .text-title {
    font-size: 20px;
    font-weight: 500;
    color: #3f3f3f;
    margin-bottom: 12px;
  }
  .text-subtext {
    font-size: 15px;
    line-height: 22px;
    color: #666;
  }
}
</style>
